import state from './modulePropertyPromotionCategoryManagementState.js'
import mutations from './modulePropertyPromotionCategoryManagementMutations.js'
import actions from './modulePropertyPromotionCategoryManagementActions.js'
import getters from './modulePropertyPromotionCategoryManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

